import React from 'react';
import img from '../images/web.svg'; 
import img2 from '../images/app.svg';
import img3 from '../images/hosting.svg';
import img4 from '../images/consultation.svg';

const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-12" >

            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="lg flex flex-col lg:mx-4 justify-center">
                            <h3 className="text-3xl  text-blue-900 
                            font-bold center">Warunki współpracy</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                    Usługi wykonujemy na podstawie umowy cywilno – prawnej. Warunki finansowe usługi są
                                    uzgadniane z Klientem indywidualnie po sprecyzowaniu zakresu prac, ilości dokumentów
                                    i zapoznaniu się z podstawowymi informacjami dotyczącymi specyfiki firmy. Rozliczenie
                                    następuje miesięcznie, przelewem na rachunek bankowy.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services;