import React from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Main from '../components/Main';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from "../components/Services";


const Home = () => {
    return (
        <>
            <Main />
            <Intro />
            <Portfolio />
            <Services />
            <Clients />
            <Cta/>
            <Footer />
        </>

    )
}

export default Home;

