import React from 'react';
import kws from '../images/clients/kws.png';
import geps from '../images/clients/geps.png';
import protergia from '../images/clients/protergia.png';

const clientImage = {
    height: '10rem',
    width: 'auto',
    mixBlendMode: 'colorBurn'
}

const Clients = () => {
    return (
        <div className="mt-8 bg-gray-100">
            {/*<section data-aos="fade-up">*/}
            {/*    <div className="my-4 py-4">*/}
            {/*            <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Warunki współpracy</h2>*/}
            {/*            <div className='flex justify-center'>*/}
            {/*                <div className='w-24 border-b-4 border-blue-900'></div>*/}
            {/*            </div>*/}
            {/*            <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">Usługi wykonujemy na podstawie umowy cywilno – prawnej. Warunki finansowe usługi są*/}
            {/*                uzgadniane z Klientem indywidualnie po sprecyzowaniu zakresu prac, ilości dokumentów*/}
            {/*                i zapoznaniu się z podstawowymi informacjami dotyczącymi specyfiki firmy. Rozliczenie*/}
            {/*                następuje miesięcznie, przelewem na rachunek bankowy.</h2>*/}
            {/*        </div>*/}
            {/*</section>*/}
        </div>
    )
}

export default Clients;