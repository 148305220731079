import React from 'react';
import { HashLink } from 'react-router-hash-link';
import logo from "../images/logo.png";
import img from "../images/web.svg";

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">

                    {/* Top area: Blocks */}
                    <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">

                        {/* 1st block */}
                        <div className="col-span-12 lg:col-span-4">
                            <div
                                className="box-border border-b-4 border-blue-900 p-8 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">
                                <img
                                    alt="logo biura"
                                    className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                                    src={logo}
                                    width={400}/>
                                <br/>
                                <div className='text-md font-medium text-gray-600'>
                                    <h5>Elżbieta Kotwica</h5>
                                    <p>ul. Malinowa 5</p>
                                    <p>37-433 Przyszów</p>
                                    <p>NIP: 8652413991</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-span-12 lg:col-span-5">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2539.332750175387!2d21.986162276830267!3d50.47214878580092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473d2f4f96f504b1%3A0xbf45ada0c5ae6489!2sBiuro%20Rachunkowe%20El%C5%BCbieta%20Kotwica!5e0!3m2!1spl!2spl!4v1715967352831!5m2!1spl!2spl"
                                    width="100%"
                                    height="350"
                                    frameBorder="0"
                                    style={{border: 0}}
                                    allowFullScreen=""
                                    aria-hidden="false"
                                    tabIndex="0"
                                />
                        </div>

                        {/*2nd block*/}
                        <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
                            <h6 className="text-[#013289] text-xl font-bold mb-4">LINKI</h6>
                            <ul className="text-md">
                                <li className="mb-2">
                                    <HashLink to="/#about"
                                              className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">O
                                        nas</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="/#products"
                                              className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Oferta</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="/contact"
                                              className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Kontakt</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="/privacy"
                                              className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Polityka prywatności</HashLink>
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
                        <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                            <div className="text-sm text-gray-200 font-semibold py-1">
                                Copyright &copy; {new Date().getFullYear()}{"  "}
                                <HashLink
                                    to="#"
                                    className=" hover:text-gray-900"
                                >
                                    Elżbieta Kotwica - Biuro Rachunkowe
                                </HashLink>. All rights reserved.
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;
