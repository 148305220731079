import React from 'react';
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";

const Privacy = () => {
    return (
        <>
            <div>
                <NavBar/>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6">
                <h1 style={{"textAlign": "center"}}>Polityka Prywatności</h1>
                Poniższa Polityka Prywatności określa <strong>zasady zapisywania i uzyskiwania dostępu do danych na
                Urządzeniach Użytkowników</strong> korzystających z Serwisu do celów świadczenia usług drogą
                elektroniczną
                przez Administratora oraz <strong>zasady gromadzenia i przetwarzania danych osobowych
                Użytkowników</strong>,
                które zostały podane przez nich osobiście i dobrowolnie za pośrednictwem narzędzi dostępnych w Serwisie.
                <h2 style={{"textAlign": "center"}}>§1 Definicje</h2>
                <ul>
                    <li><strong>Serwis</strong> - serwis internetowy "Elżbieta Kotwica Biuro Rachunkowe" działający pod
                        adresem https://ekotwica.pl
                    </li>
                    <li><strong>Serwis zewnętrzny</strong> - serwisy internetowe partnerów, usługodawców lub
                        usługobiorców
                        współpracujących z Administratorem
                    </li>
                    <li><strong>Administrator Serwisu / Danych</strong> - Administratorem Serwisu oraz Administratorem
                        Danych (dalej Administrator) jest Elżbieta Kotwica, prowadząca działalność pod adresem: ul.
                        Malinowa
                        5, 37-433 Pryszów o nadanym numerze identyfikacji podatkowej (NIP): 8652413991, świadcząca
                        usługi
                        drogą elektroniczną za pośrednictwem Serwisu
                    </li>
                    <li><strong>Użytkownik</strong> - osoba fizyczna, dla której Administrator świadczy usługi drogą
                        elektroniczną za pośrednictwem Serwisu.
                    </li>
                    <li><strong>Urządzenie</strong> - elektroniczne urządzenie wraz z oprogramowaniem, za pośrednictwem
                        którego Użytkownik uzyskuje dostęp do Serwisu
                    </li>
                    <li><strong>Cookies (ciasteczka)</strong> - dane tekstowe gromadzone w formie plików zamieszczanych
                        na
                        Urządzeniu Użytkownika
                    </li>
                    <li><strong>RODO</strong> - Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
                        kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i
                        w
                        sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne
                        rozporządzenie
                        o ochronie danych)
                    </li>
                    <li><strong>Dane osobowe</strong> - oznaczają informacje o zidentyfikowanej lub możliwej do
                        zidentyfikowania osobie fizycznej („osobie, której dane dotyczą”); możliwa do zidentyfikowania
                        osoba
                        fizyczna to osoba, którą można bezpośrednio lub pośrednio zidentyfikować, w szczególności na
                        podstawie identyfikatora takiego jak imię i nazwisko, numer identyfikacyjny, dane o lokalizacji,
                        identyfikator internetowy lub jeden bądź kilka szczególnych czynników określających fizyczną,
                        fizjologiczną, genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną tożsamość osoby
                        fizycznej
                    </li>
                    <li><strong>Przetwarzanie</strong> - oznacza operację lub zestaw operacji wykonywanych na danych
                        osobowych lub zestawach danych osobowych w sposób zautomatyzowany lub niezautomatyzowany, taką
                        jak
                        zbieranie, utrwalanie, organizowanie, porządkowanie, przechowywanie, adaptowanie lub
                        modyfikowanie,
                        pobieranie, przeglądanie, wykorzystywanie, ujawnianie poprzez przesłanie, rozpowszechnianie lub
                        innego rodzaju udostępnianie, dopasowywanie lub łączenie, ograniczanie, usuwanie lub niszczenie;
                    </li>
                    <li><strong>Ograniczenie przetwarzania</strong> - oznacza oznaczenie przechowywanych danych
                        osobowych w
                        celu ograniczenia ich przyszłego przetwarzania
                    </li>
                    <li><strong>Profilowanie</strong> - oznacza dowolną formę zautomatyzowanego przetwarzania danych
                        osobowych, które polega na wykorzystaniu danych osobowych do oceny niektórych czynników
                        osobowych
                        osoby fizycznej, w szczególności do analizy lub prognozy aspektów dotyczących efektów pracy tej
                        osoby fizycznej, jej sytuacji ekonomicznej, zdrowia, osobistych preferencji, zainteresowań,
                        wiarygodności, zachowania, lokalizacji lub przemieszczania się
                    </li>
                    <li><strong>Zgoda</strong> - zgoda osoby, której dane dotyczą oznacza dobrowolne, konkretne,
                        świadome i
                        jednoznaczne okazanie woli, którym osoba, której dane dotyczą, w formie oświadczenia lub
                        wyraźnego
                        działania potwierdzającego, przyzwala na przetwarzanie dotyczących jej danych osobowych
                    </li>
                    <li><strong>Naruszenie ochrony danych osobowych</strong> - oznacza naruszenie bezpieczeństwa
                        prowadzące
                        do przypadkowego lub niezgodnego z prawem zniszczenia, utracenia, zmodyfikowania,
                        nieuprawnionego
                        ujawnienia lub nieuprawnionego dostępu do danych osobowych przesyłanych, przechowywanych lub w
                        inny
                        sposób przetwarzanych
                    </li>
                    <li><strong>Pseudonimizacja</strong> - oznacza przetworzenie danych osobowych w taki sposób, by nie
                        można ich było już przypisać konkretnej osobie, której dane dotyczą, bez użycia dodatkowych
                        informacji, pod warunkiem że takie dodatkowe informacje są przechowywane osobno i są objęte
                        środkami
                        technicznymi i organizacyjnymi uniemożliwiającymi ich przypisanie zidentyfikowanej lub możliwej
                        do
                        zidentyfikowania osobie fizycznej
                    </li>
                    <li><strong>Anonimizacja</strong> - Anonimizacja danych to nieodwracalny proces operacji na danych,
                        który niszczy / nadpisuje "dane osobowe" uniemożliwiając identyfikację, lub powiązanie danego
                        rekordu z konkretnym użytkownikiem lub osobą fizyczną.
                    </li>
                </ul>
                <h2 style={{"textAlign": "center"}}>§2 Inspektor Ochrony Danych</h2>
                Na podstawie Art. 37 RODO, Administrator nie powołał Inspektora Ochrony Danych.

                W sprawach dotyczących przetwarzania danych, w tym danych osobowych, należy kontaktować się bezpośrednio
                z
                Administratorem poprzez formularz kontaktowy znajdujący się na stronie lub adres e-mail:
                biuro@ekotwica.pl
                <h2 style={{"textAlign": "center"}}>§3 Rodzaje Plików Cookies</h2>
                <ul>
                    <li><strong>Cookies wewnętrzne</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika
                        przez system teleinformatyczny Serwisu
                    </li>
                    <li><strong>Cookies zewnętrzne</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika
                        przez systemy teleinformatyczne Serwisów zewnętrznych. Skrypty Serwisów zewnętrznych, które mogą
                        umieszczać pliki Cookies na Urządzeniach Użytkownika zostały świadomie umieszczone w Serwisie
                        poprzez skrypty i usługi udostępnione i zainstalowane w Serwisie
                    </li>
                    <li><strong>Cookies sesyjne</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika
                        przez
                        Serwis <span id="sz1" style={{"display": "none"}}>lub Serwisy zewnętrzne</span> podczas jednej
                        sesji
                        danego Urządzenia. Po zakończeniu sesji pliki są usuwane z Urządzenia Użytkownika.
                    </li>
                    <li><strong>Cookies trwałe</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika
                        przez
                        Serwis <span id="sz2" style={{"display": "none"}}>lub Serwisy zewnętrzne</span> do momentu ich
                        ręcznego
                        usunięcia. Pliki nie są usuwane automatycznie po zakończeniu sesji Urządzenia chyba że
                        konfiguracja
                        Urządzenia Użytkownika jest ustawiona na tryb usuwanie plików Cookie po zakończeniu sesji
                        Urządzenia.
                    </li>
                </ul>
                <h2 style={{"textAlign": "center"}}>§4 Bezpieczeństwo składowania danych</h2>
                <ul>
                    <li><strong>Mechanizmy składowania i odczytu plików Cookie</strong> - Mechanizmy składowania,
                        odczytu i
                        wymiany danych pomiędzy Plikami Cookies zapisywanymi na Urządzeniu Użytkownika a Serwisem są
                        realizowane poprzez wbudowane mechanizmy przeglądarek internetowych i nie pozwalają na
                        pobieranie
                        innych danych z Urządzenia Użytkownika lub danych innych witryn internetowych, które odwiedzał
                        Użytkownik, w tym danych osobowych ani informacji poufnych. Przeniesienie na Urządzenie
                        Użytkownika
                        wirusów, koni trojańskich oraz innych robaków jest także praktycznie niemożliwe.
                    </li>
                    <li><strong>Cookie wewnętrzne</strong> - zastosowane przez Administratora pliki Cookie są bezpieczne
                        dla
                        Urządzeń Użytkowników i nie zawierają skryptów, treści lub informacji mogących zagrażać
                        bezpieczeństwu danych osobowych lub bezpieczeństwu Urządzenia z którego korzysta Użytkownik.
                    </li>
                    <li><strong>Cookie zewnętrzne</strong> - Administrator dokonuje wszelkich możliwych działań w celu
                        weryfikacji i doboru partnerów serwisu w kontekście bezpieczeństwa Użytkowników. Administrator
                        do
                        współpracy dobiera znanych, dużych partnerów o globalnym zaufaniu społecznym. Nie posiada on
                        jednak
                        pełnej kontroli nad zawartością plików Cookie pochodzących od zewnętrznych partnerów. Za
                        bezpieczeństwo plików Cookie, ich zawartość oraz zgodne z licencją wykorzystanie przez
                        zainstalowane
                        w serwisie Skrypty, pochodzących z Serwisów zewnętrznych, Administrator nie ponosi
                        odpowiedzialności
                        na tyle na ile pozwala na to prawo. Lista partnerów zamieszczona jest w dalszej części Polityki
                        Prywatności.
                    </li>
                    <li><strong>Kontrola plików Cookie</strong>
                        <ul>
                            <li>Użytkownik może w dowolnym momencie, samodzielnie zmienić ustawienia dotyczące
                                zapisywania,
                                usuwania oraz dostępu do danych zapisanych plików Cookies przez każdą witrynę
                                internetową
                            </li>
                            <li>Informacje o sposobie wyłączenia plików Cookie w najpopularniejszych przeglądarkach
                                komputerowych dostępne są na stronie: <a
                                    href="https://nety.pl/jak-wylaczyc-pliki-cookie/"
                                    rel="external">jak wyłączyć cookie</a> lub u
                                jednego ze wskazanych dostawców:
                                <ul>
                                    <li><a
                                        href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=pl"
                                        rel="nofollow external">Zarządzanie plikami cookies w
                                        przeglądarce <strong>Chrome</strong></a></li>
                                    <li><a href="https://help.opera.com/pl/latest/web-preferences/"
                                           rel="nofollow external">Zarządzanie
                                        plikami cookies w przeglądarce <strong>Opera</strong></a></li>
                                    <li><a href="https://support.mozilla.org/pl/kb/blokowanie-ciasteczek"
                                           rel="nofollow external">Zarządzanie plikami cookies w
                                        przeglądarce <strong>FireFox</strong></a></li>
                                    <li><a
                                        href="https://support.microsoft.com/pl-pl/help/4027947/microsoft-edge-delete-cookies"
                                        rel="nofollow external">Zarządzanie plikami cookies w
                                        przeglądarce <strong>Edge</strong></a></li>
                                    <li><a href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac"
                                           rel="nofollow external">Zarządzanie plikami cookies w
                                        przeglądarce <strong>Safari</strong></a></li>
                                    <li><a
                                        href="https://windows.microsoft.com/pl-pl/internet-explorer/delete-manage-cookies#ie=ie-11"
                                        rel="nofollow external">Zarządzanie plikami cookies w przeglądarce <strong>Internet
                                        Explorer 11</strong></a></li>
                                </ul>
                            </li>
                            <li>Użytkownik może w dowolnym momencie usunąć wszelkie zapisane do tej pory pliki Cookie
                                korzystając z narzędzi Urządzenia Użytkownika, za pośrednictwem którego Użytkownik
                                korzysta
                                z usług Serwisu.
                            </li>
                        </ul>
                    </li>
                    <li><strong>Zagrożenia po stronie Użytkownika </strong> - Administrator stosuje wszelkie możliwe
                        środki
                        techniczne w celu zapewnienia bezpieczeństwa danych umieszczanych w plikach Cookie. Należy
                        jednak
                        zwrócić uwagę, że zapewnienie bezpieczeństwa tych danych zależy od obu stron w tym działalności
                        Użytkownika. Administrator nie bierze odpowiedzialności za przechwycenie tych danych, podszycie
                        się
                        pod sesję Użytkownika lub ich usunięcie, na skutek świadomej lub nieświadomej działalność
                        Użytkownika, wirusów, koni trojańskich i innego oprogramowania szpiegującego, którymi może jest
                        lub
                        było zainfekowane Urządzenie Użytkownika. Użytkownicy w celu zabezpieczenia się przed tymi
                        zagrożeniami powinni stosować się do <a
                            href="https://nety.pl/cyberbezpieczenstwo/zasady-ogolne-korzystania-z-sieci-internet/"
                            rel="external">zasad bezpiecznego korzystania z sieci internet</a>.
                    </li>
                    <li><strong>Przechowywanie danych osobowych</strong> - Administrator zapewnia, że dokonuje wszelkich
                        starań, by przetwarzane dane osobowe wprowadzone dobrowolnie przez Użytkowników były bezpieczne,
                        dostęp do nich był ograniczony i realizowany zgodnie z ich przeznaczeniem i celami
                        przetwarzania.
                        Administrator zapewnia także, że dokonuje wszelkich starań w celu zabezpieczenia posiadanych
                        danych
                        przed ich utratą, poprzez stosowanie odpowiednich zabezpieczeń fizycznych jak i organizacyjnych.
                    </li>
                </ul>
                <h2 style={{"textAlign": "center"}}>§5 Cele do których wykorzystywane są pliki Cookie</h2>
                <ul id="cele">
                    <li>Usprawnienie i ułatwienie dostępu do Serwisu</li>
                    <li>Personalizacja Serwisu dla Użytkowników</li>
                    <li>Prowadzenie statystyk (użytkowników, ilości odwiedzin, rodzajów urządzeń, łącze itp.)</li>
                </ul>
                <h2 style={{"textAlign": "center"}}>§6 Cele przetwarzania danych osobowych</h2>
                Dane osobowe dobrowolnie podane przez Użytkowników są przetwarzane w jednym z następujących celów:
                <ul>
                    <li>Realizacji usług elektronicznych:</li>
                    <li>Komunikacji Administratora z Użytkownikami w sprawach związanych z Serwisem oraz ochrony
                        danych
                    </li>
                    <li>Zapewnienia prawnie uzasadnionego interesu Administratora</li>
                </ul>
                Dane o Użytkownikach gromadzone anonimowo i automatycznie są przetwarzane w jednym z następujących
                celów:
                <ul>
                    <li>Prowadzenie statystyk</li>
                    <li>Zapewnienia prawnie uzasadnionego interesu Administratora</li>
                </ul>
                <h2 style={{"textAlign": "center"}}>§7 Pliki Cookies Serwisów zewnętrznych</h2>
                <p id="zewinfo">Administrator w Serwisie wykorzystuje skrypty javascript i komponenty webowe partnerów,
                    którzy mogą umieszczać własne pliki cookies na Urządzeniu Użytkownika. Pamiętaj, że w ustawieniach
                    swojej przeglądarki możesz sam decydować o dozwolonych plikach cookies jakie mogą być używane przez
                    poszczególne witryny internetowe. Poniżej znajduje się lista partnerów lub ich usług
                    zaimplementowanych
                    w Serwisie, mogących umieszczać pliki cookies:</p>

                <ul id="zewnetrzne"></ul>
                Usługi świadczone przez podmioty trzecie są poza kontrolą Administratora. Podmioty te mogą w każdej
                chwili
                zmienić swoje warunki świadczenia usług, polityki prywatności, cel przetwarzania danych oraz sposów
                wykorzystywania plików cookie.
                <h2 style={{"textAlign": "center"}}>§8 Dostęp do danych osobowych przez podmioty trzecie</h2>
                Co do zasady jedynym odbiorcą danych osobowych podawanych przez Użytkowników jest Administrator. Dane
                gromadzone w ramach świadczonych usług nie są przekazywane ani odsprzedawane podmiotom trzecim.

                Dostęp do danych (najczęściej na podstawie Umowy powierzenia przetwarzania danych) mogą posiadać
                podmioty,
                odpowiedzialne za utrzymania infrastruktury i usług niezbędnych do prowadzenia serwisu.
                <h2 style={{"textAlign": "center"}}>§9 Sposób przetwarzania danych osobowych</h2>
                <strong>Dane osobowe podane dobrowolnie przez Użytkowników:</strong>
                <ul>
                    <li>Dane osobowe nie będą przekazywane poza Unię Europejską, chyba że zostały opublikowane na skutek
                        indywidualnego działania Użytkownika (np. wprowadzenie komentarza lub wpisu), co sprawi, że dane
                        będą dostępne dla każdej osoby odwiedzającej serwis.
                    </li>
                    <li>Dane osobowe nie będą wykorzystywane do zautomatyzowanego podejmowania decyzji (profilowania).
                    </li>
                    <li>Dane osobowe nie będą odsprzedawane podmiotom trzecim.</li>
                </ul>
                <strong>Dane anonimowe (bez danych osobowych) gromadzone automatycznie:</strong>
                <ul>
                    <li>Dane anonimiwe (bez danych osobowych) będą przekazywane poza Unię Europejską.</li>
                    <li>Dane anonimiwe (bez danych osobowych) nie będą wykorzystywane do zautomatyzowanego podejmowania
                        decyzji (profilowania).
                    </li>
                    <li>Dane anonimiwe (bez danych osobowych) nie będą odsprzedawane podmiotom trzecim.</li>
                </ul>
                <h2 style={{"textAlign": "center"}}>§10 Podstawy prawne przetwarzania danych osobowych</h2>
                Serwis gromadzi i przetwarza dane Użytkowników na podstawie:
                <ul>
                    <li>Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
                        sprawie
                        ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego
                        przepływu
                        takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych)
                        <ul>
                            <li>art. 6 ust. 1 lit. a
                                <small>osoba, której dane dotyczą wyraziła zgodę na przetwarzanie swoich danych
                                    osobowych w
                                    jednym lub większej liczbie określonych celów</small></li>
                            <li>art. 6 ust. 1 lit. b
                                <small>przetwarzanie jest niezbędne do wykonania umowy, której stroną jest osoba, której
                                    dane dotyczą, lub do podjęcia działań na żądanie osoby, której dane dotyczą, przed
                                    zawarciem umowy</small></li>
                            <li>art. 6 ust. 1 lit. f
                                <small>przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych
                                    interesów
                                    realizowanych przez administratora lub przez stronę trzecią</small></li>
                        </ul>
                    </li>
                    <li>Ustawa z dnia 10 maja 2018 r. o ochronie danych osobowych (Dz.U. 2018 poz. 1000)</li>
                    <li>Ustawa z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (Dz.U. 2004 nr 171 poz. 1800)</li>
                    <li>Ustawa z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych (Dz. U. 1994 Nr 24 poz.
                        83)
                    </li>
                </ul>
                <h2 style={{"textAlign": "center"}}>§11 Okres przetwarzania danych osobowych</h2>
                <strong>Dane osobowe podane dobrowolnie przez Użytkowników:</strong>

                Co do zasady wskazane dane osobowe są przechowywane wyłącznie przez okres świadczenia Usługi w ramach
                Serwisu przez Administratora. Są one usuwane lub anonimizowane w okresie do 30 dni od chwili zakończenia
                świadczenia usług (np. usunięcie zarejestrowanego konta użytkownika, wypisanie z listy Newsletter, itp.)

                Wyjątek stanowi sytuacja, która wymaga zabezpieczenia prawnie uzasadnionych celów dalszego przetwarzania
                tych danych przez Administratora. W takiej sytuacji Administrator będzie przechowywał wskazane dane, od
                czasu żądania ich usunięcia przez Użytkownika, nie dłużej niż przez okres 3 lat w przypadku naruszenia
                lub
                podejrzenia naruszenia zapisów regulaminu serwisu przez Użytkownika

                <strong>Dane anonimowe (bez danych osobowych) gromadzone automatycznie:</strong>

                Anonimowe dane statystyczne, niestanowiące danych osobowych, są przechowywane przez Administratora w
                celu
                prowadzenia statystyk serwisu przez czas nieoznaczony
                <h2 style={{"textAlign": "center"}}>§12 Prawa Użytkowników związane z przetwarzaniem danych
                    osobowych</h2>
                Serwis gromadzi i przetwarza dane Użytkowników na podstawie:
                <ul>
                    <li><strong>Prawo dostępu do danych osobowych</strong>
                        Użytkownikom przysługuje prawo uzyskania dostępu do swoich danych osobowych, realizowane na
                        żądanie
                        złożone do Administratora
                    </li>
                    <li><strong>Prawo do sprostowania danych osobowych</strong>
                        Użytkownikom przysługuje prawo żądania od Administratora niezwłocznego sprostowania danych
                        osobowych, które są nieprawidłowe lub / oraz uzupełnienia niekompletnych danych osobowych,
                        realizowane na żądanie złożone do Administratora
                    </li>
                    <li><strong>Prawo do usunięcia danych osobowych</strong>
                        Użytkownikom przysługuje prawo żądania od Administratora niezwłocznego usunięcia danych
                        osobowych,
                        realizowane na żądanie złożone do AdministratoraW przypadku kont użytkowników, usunięcie danych
                        polega na anonimizacji danych umożliwiających identyfikację Użytkownika. Administrator zastrzega
                        sobie prawo wstrzymania realizacji żądania usunięcia danych w celu ochrony prawnie uzasadnionego
                        interesu Administratora (np. w gdy Użytkownik dopuścił się naruszenia Regulaminu czy dane
                        zostały
                        pozyskane wskutek prowadzonej korespondencji).
                        W przypadku usługi Newsletter, Użytkownik ma możliwość samodzielnego usunięcia swoich danych
                        osobowych korzystając z odnośnika umieszczonego w każdej przesyłanej wiadomości e-mail.
                    </li>
                    <li><strong>Prawo do ograniczenia przetwarzania danych osobowych</strong>
                        Użytkownikom przysługuje prawo ograniczenia przetwarzania danych osobowych w przypadkach
                        wskazanych
                        w art. 18 RODO, m.in. kwestionowania prawidłowość danych osobowych, realizowane na żądanie
                        złożone
                        do Administratora
                    </li>
                    <li><strong>Prawo do przenoszenia danych osobowych</strong>
                        Użytkownikom przysługuje prawo uzyskania od Administratora, danych osobowych dotyczących
                        Użytkownika
                        w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego,
                        realizowane
                        na żądanie złożone do Administratora
                    </li>
                    <li><strong>Prawo wniesienia sprzeciwu wobec przetwarzania danych osobowych</strong>
                        Użytkownikom przysługuje prawo wniesienia sprzeciwu wobec przetwarzania jego danych osobowych w
                        przypadkach określonych w art. 21 RODO, realizowane na żądanie złożone do Administratora
                    </li>
                    <li><strong>Prawo wniesienia skargi</strong>
                        Użytkownikom przysługuje prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną
                        danych osobowych.
                    </li>
                </ul>
                <h2 style={{"textAlign": "center"}}>§13 Kontakt do Administratora</h2>
                Z Administratorem można skontaktować się w jeden z poniższych sposobów
                <ul>
                    <li><strong>Adres pocztowy</strong> - ul. Malinowa 5, 37-433 Pryszów</li>
                    <li><strong>Adres poczty elektronicznej</strong> - biuro@ekotwica.pl</li>
                    <li><strong>Formularz kontaktowy</strong> - dostępny pod adresem: /kontakt</li>
                </ul>
                <h2 style={{"textAlign": "center"}}>§14 Wymagania Serwisu</h2>
                <ul>
                    <li>Ograniczenie zapisu i dostępu do plików Cookie na Urządzeniu Użytkownika może spowodować
                        nieprawidłowe działanie niektórych funkcji Serwisu.
                    </li>
                    <li>Administrator nie ponosi żadnej odpowiedzialności za nieprawidłowo działające funkcje Serwisu w
                        przypadku gdy Użytkownik ograniczy w jakikolwiek sposób możliwość zapisywania i odczytu plików
                        Cookie.
                    </li>
                </ul>
                <h2 style={{"textAlign": "center"}}>§15 Zmiany w Polityce Prywatności</h2>
                <ul>
                    <li>Administrator zastrzega sobie prawo do dowolnej zmiany niniejszej Polityki Prywatności bez
                        konieczności informowania o tym Użytkowników w zakresie stosowania i wykorzystywania danych
                        anonimowych lub stosowania plików Cookie.
                    </li>
                    <li>Administrator zastrzega sobie prawo do dowolnej zmiany niniejszej Polityki Prywatności w
                        zakresie
                        przetwarzania Danych Osobowych, o czym poinformuje Użytkowników posiadających konta użytkownika
                        lub
                        zapisanych do usługi newsletter, za pośrednictwem poczty elektronicznej w terminie do 7 dni od
                        zmiany zapisów. Dalsze korzystanie z usług oznacza zapoznanie się i akceptację wprowadzonych
                        zmian
                        Polityki Prywatności. W przypadku w którym Użytkownik nie będzie się zgadzał z wprowadzonymi
                        zmianami, ma obowiązek usunąć swoje konto z Serwisu lub wypisać się z usługi Newsletter.
                    </li>
                    <li>Wprowadzone zmiany w Polityce Prywatności będą publikowane na tej podstronie Serwisu.</li>
                    <li>Wprowadzone zmiany wchodzą w życie z chwilą ich publikacji.</li>
                </ul>
            </div>
            <Footer/>
        </>
    )
}

export default Privacy;